'use-strict';

const hide = (source, target) => {
    TweenMax.fromTo(target, .225, {overflow: 'hidden'}, {
        height: 0,
        ease: Power3.easeOut,
        onComplete () {
            source.classList.add('collapsed');
            source.setAttribute('aria-expanded', false);
            target.classList.remove('show');
            TweenMax.set(target, {clearProps: 'all'});
        }
    });
};

const show = (source, target) => {
    // make target visible first to get its height
    target.classList.add('show');
    const rect = target.getBoundingClientRect();

    TweenMax.fromTo(target, .25, {overflow: 'hidden', height: 0}, {
        height: rect.height,
        ease: Power3.easeOut,
        onComplete () {
            source.classList.remove('collapsed');
            source.setAttribute('aria-expanded', true);
            TweenMax.set(target, {clearProps: 'all'});
        }
    });
};

export const sideNav = () => {
    const sideNavs = document.querySelectorAll('.side-nav .side-nav-link');
    for (let sn = 0; sn < sideNavs.length; sn++) {
        const el = sideNavs[sn];

        // skip element if it has no sibling (collapsible element)
        if (el.nextElementSibling === null) {
            continue;
        }

        el.addEventListener('click', function (e) {
            e.preventDefault();

            const target = document.querySelector(el.getAttribute('href'));
            if (target === null) {
                console.warn('Collapse target element not found');
                return;
            }

            // if the collapse for the clicked element is visible, hide it
            if (el.classList.contains('collapsed') === false) {
                hide(el, target);
            } else {
                // first check if we have an open element with the same parent element
                const parentAttr = el.getAttribute('data-parent');
                const parentEl = document.querySelector(parentAttr);
                const openCollapseEl = parentEl.querySelector(`.side-nav-link:not(.collapsed)[data-parent="${parentAttr}"]`);

                // if there is hide it
                if (openCollapseEl !== null) {
                    const closeTarget = document.querySelector(openCollapseEl.getAttribute('href'));
                    hide(openCollapseEl, closeTarget);
                }

                show(el, target);
            }
        });
    }
};