'use-strict';

import {highlight, delegate} from '../helper/dom';

export const sendSearchRequest = (url, query) => {
    return fetch(`${url}&query=${query}`, {
        method: 'GET'
    }).then(resp => {
        return resp.json();
    });
};

export const addHighlightedResults = (context, query, results, addidionalData = []) => {
    if (results.length === 0 && context.noresults !== null) {
        let item = document.createElement('span');
        item.classList.add('item');
        item.textContent = context.noresults;
        context.resultsElement.appendChild(item);
        return;
    }

    for (let i = 0; i < results.length; i++) {
        let item;
        // if there is a link create an <a> tag
        if ('link' in results[i]) {
            item = document.createElement('a');
            item.setAttribute('href', results[i].link);
        } else {
            item = document.createElement('span');
        }

        item.classList.add('item');
        item.innerHTML = highlight(results[i].text, query);

        // add additional data as data attributes
        for (let j = 0; j < addidionalData.length; j++) {
            item.setAttribute('data-'+addidionalData[j], results[i][addidionalData[j]]);
        }

        // add element to container
        context.resultsElement.appendChild(item);
    }
};

export const Search = (el, showBackdrop = false) => {
    if (el == null) {
        console.error('No serach box element provided');
        return;
    }

    const input = el.querySelector('input');
    input.value = ''; // reset input value
    const formUrl = input.form.action;
    const noResultsText = input.getAttribute('data-noresults');
    const position = el.getAttribute('data-position');
    const backdrop = document.querySelector('.search-backdrop');
    const searchPreview = document.createElement('div');
    searchPreview.className = 'search-preview posa';
    searchPreview.setAttribute('aria-hidden', true);
    const resultSelectedEventName = 'resultSelected';
    const resultSelectedEvent = new Event(resultSelectedEventName);
    const inputEvent = new Event('input');
    let resultsVisible = false;
    let selectedResult = null;
    let inputValue = '';
    let pos;

    if (position === 'self' || position === null) {
        pos = el;
        el.appendChild(searchPreview);
    } else {
        pos = document.querySelector(position);
        pos.appendChild(searchPreview);
    }

    // set input value to clicked result item
    delegate(searchPreview, 'click', '.item', function () {
        selectedResult = this;
        input.value = this.textContent;
        el.dispatchEvent(resultSelectedEvent);
    }, {passive: true});

    // show search results element if there are already some
    input.addEventListener('focus', function () {
        if (this.value.length > 1) {
            resultsVisible = true;
            searchPreview.classList.add('visible');
            searchPreview.setAttribute('aria-hidden', true);

            if (showBackdrop === true) {
                backdrop.classList.add('visible');
            }

            // fire keyup event if value in input changed somehow
            this.dispatchEvent(inputEvent);
        }
    });

    // focus first result item on arrow key down
    input.addEventListener('keydown', function (e) {
        if (resultsVisible === true) {
            if (e.keyCode === 40) {
                e.preventDefault();
                searchPreview.children[0].focus();
                inputValue = input.value;
                input.value = searchPreview.children[0].textContent;
            }
        }
    });

    // switch focus of result items on arrow key up and down
    delegate(searchPreview, 'keydown', '.item', function (e) {
        e.preventDefault();

        // submit form on either space or enter
        if (e.keyCode === 32 || e.keyCode === 13) {
            input.form.submit();
        }

        // down
        if (e.keyCode === 40) {
            const next = this.nextElementSibling;
            if (next !== null) {
                next.focus();
                input.value = next.textContent;
            }
        // up
        } else if (e.keyCode === 38) {
            const prev = this.previousElementSibling;
            if (prev !== null) {
                prev.focus();
                input.value = prev.textContent;
            // focus input if first child
            } else {
                input.focus();
                input.value = inputValue;
            }
        }
    });

    // hide results on click outside
    window.addEventListener('click', function (e) {
        if (e.target === input) {
            return;
        }

        resultsVisible = false;
        searchPreview.classList.remove('visible');
        searchPreview.setAttribute('aria-hidden', true);

        if (showBackdrop === true) {
            backdrop.classList.remove('visible');
        }
    }, {passive: true});

    return {
        init (queryFunction, context) {
            // if we have a context (to an "instance" of this Search function) add it as a parameter
            if (context != null) {
                input.addEventListener('keyup', function () {
                    queryFunction.call(this, context);
                });
            } else {
                input.addEventListener('keyup', queryFunction);
            }
        },
        get resultsElement () {
            return searchPreview;
        },
        get backdropEl () {
            return backdrop;
        },
        get previewVisible () {
            return resultsVisible;
        },
        set previewVisible (isVisible) {
            resultsVisible = isVisible;
        },
        get selected () {
            return selectedResult;
        },
        get selectedEvent () {
            return resultSelectedEventName;
        },
        get form () {
            return input.form;
        },
        get action () {
            return formUrl;
        },
        get noresults () {
            return noResultsText;
        }
    };
};