'use-strict';

import {Search, sendSearchRequest, addHighlightedResults} from './search';
import {fixedEncodeURIComponent} from '../helper/dom';

export const headerSearch = () => {
    const searchEl = document.querySelector('#header .search-box');
    const HeaderSearch = Search(searchEl, true);
    const searchUrl = searchEl.getAttribute('data-url');
    HeaderSearch.init(function () {
        const query = this.value;
        if (query.length > 2) {
            // if preview not yet visible, make it
            if (HeaderSearch.previewVisible === false) {
                HeaderSearch.backdropEl.classList.add('visible');
                HeaderSearch.resultsElement.classList.add('visible');
                HeaderSearch.resultsElement.setAttribute('aria-hidden', false);
                HeaderSearch.previewVisible = true;
            }

            sendSearchRequest(searchUrl, fixedEncodeURIComponent(query)).then(data => {
                // remove search results
                HeaderSearch.resultsElement.innerHTML = '';

                addHighlightedResults(HeaderSearch, query, data);
            }).catch(error => {
                throw new Error(error);
            });
        } else {
            HeaderSearch.resultsElement.innerHTML = '';
            HeaderSearch.resultsElement.setAttribute('aria-hidden', true);
            HeaderSearch.backdropEl.classList.remove('visible');
            HeaderSearch.previewVisible = false;
        }
    });
};