import { MOBILMEDIA } from './helper/constants';
import { headerSearch } from './components/headerSearch';
import { contactAddress } from './components/contactAddress';
import { slider } from './components/bannerSlider';
import { sideNav } from './components/sideNav';

export default function () {
    headerSearch();
    contactAddress();
    slider();
    sideNav();

    const tabLinks = document.querySelectorAll('a[data-tablink]');
    for (let tl = 0; tl < tabLinks.length; tl++) {
        const tel = tabLinks[tl];
        const tabid = tel.getAttribute('data-tablink');
        if (tabid !== '') {
            tel.setAttribute('href', `${tel.getAttribute('href')}#tab=${tabid}`);
        }
    }

    let mobileHeaderLoaded = false;
    let desktopNavLoaded = false;
    // let pensionCalcLoaded = false;
    let customSelectLoaded = false;
    let responsiveTableLoaded = false;

    const loadMobileHeader = () => {
        import('./components/mobileHeader.js').then((mobileHeader) => {
            mobileHeaderLoaded = true;
            return mobileHeader.init();
        }).catch((err) => {
            console.warn('Chunk mobileHeader.js loading failed', err);
        });
    };

    const loadDesktopNav = () => {
        import('./components/navigation.js').then((navigation) => {
            desktopNavLoaded = true;
            return navigation.init();
        }).catch((err) => {
            console.warn('Chunk navigation.js loading failed', err);
        });
    };

    // const loadPensionCalc = () => {
    //     if (document.querySelector('#calcTabs') !== null) {
    //         import('./pages/pensionCalc.js').then((calc) => {
    //             pensionCalcLoaded = true;
    //             return calc.default();
    //         }).catch((err) => {
    //             console.warn('Chunk pensionCalc.js loading failed', err);
    //         });
    //     }
    // };

    const loadCustomSelect = () => {
        if (document.querySelector('.custom-select') !== null) {
            import('./components/select.js').then((select) => {
                customSelectLoaded = true;
                return select.init();
            }).catch((err) => {
                console.warn('Chunk select.js loading failed', err);
            });
        }
    };

    if (document.querySelector('.selectr-select') !== null) {
        import('./components/selectWithSearch.js').then((select) => {
            customSelectLoaded = true;
            return select.init();
        }).catch((err) => {
            console.warn('Chunk selectWithSearch.js loading failed', err);
        });
    }

    const loadResponsiveTable = () => {
        if (document.querySelector('main table') !== null) {
            import('./components/responsiveTable.js').then((table) => {
                responsiveTableLoaded = true;
                return table.init();
            }).catch((err) => {
                console.warn('Chunk responsiveTable.js loading failed', err);
            });
        }
    };

    // conditionaly load scripts that are only needed for certain viewports
    // but only if the viewport changed
    MOBILMEDIA.addListener((e) => {
        if (e.matches) {
            if (mobileHeaderLoaded === false) loadMobileHeader();
            if (responsiveTableLoaded === false) loadResponsiveTable();
        } else {
            if (desktopNavLoaded === false) loadDesktopNav();
            // if (pensionCalcLoaded === false) loadPensionCalc();
            if (customSelectLoaded === false) loadCustomSelect();
        }
    });

    // conditionaly load scripts that are only needed for certain viewports
    if (MOBILMEDIA.matches) {
        loadMobileHeader();
        loadResponsiveTable();
    } else {
        loadDesktopNav();
        // loadPensionCalc();
        loadCustomSelect();
    }

    if (document.querySelector('.home') !== null) {
        import('./pages/home.js').then((home) => {
            return home.init();
        }).catch((err) => {
            console.warn('Chunk home.js loading failed', err);
        });
    }

    if (document.querySelector('.nav-tabs') !== null || document.querySelector('.accordion') !== null) {
        import('../libs/bs-4-native').then((bsn) => {
            const tabs = document.querySelectorAll('.nav-tabs .nav-link');
            for (let i = 0; i < tabs.length; i++) {
                const el = tabs[i];
                // fade in new tab content
                el.addEventListener('hide.bs.tab', (e) => {
                    const href = e.relatedTarget.getAttribute('href');
                    TweenMax.fromTo(href, .3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
                }, false);
            }

            // look for hashes with a tab id to open
            const { hash } = window.location;
            if (hash !== '' && hash.indexOf('#tab=') !== -1) {
                const tab = document.getElementById(hash.replace('#tab=', ''));
                if (tab !== null) {
                    tab.Tab.show();
                }
            }
        }).catch((err) => {
            console.warn('Chunk bs4-native.js loading failed', err);
        });
    }

    if (document.querySelector('.glossar-item') !== null) {
        import('./components/glossary.js').then((glossary) => {
            return glossary.init();
        }).catch((err) => {
            console.warn('Chunk glossary.js loading failed', err);
        });
        import('./components/glossarySearch.js').then((glossary) => {
            return glossary.searchGlossary();
        }).catch((err) => {
            console.warn('Chunk glossarySearch.js loading failed', err);
        });
    }

    if (document.querySelector('#calcTabs') !== null) {
        import('./pages/pensionCalc.js').then((calc) => {
            return calc.default();
        }).catch((err) => {
            console.warn('Chunk pensionCalc.js loading failed', err);
        });
    }

    if (document.querySelector('.lightbox') !== null) {
        import('./components/lightbox.js').then((lightbox) => {
            return lightbox.initPhotoSwipe('.lightbox');
        }).catch((err) => {
            console.warn('Chunk lightbox.js loading failed', err);
        });
    }

    if (document.querySelector('#downloadTabs .cat-switch') !== null) {
        import('./components/downloadCatSwitch.js').then((catSwitch) => {
            return catSwitch.init();
        }).catch((err) => {
            console.warn('Chunk downloadCatSwitch.js loading failed', err);
        });
    }

    // load url from svg map links with JS (iOS Bug)
    const mapLinks = document.querySelectorAll('#portfolio-map a');
    if (mapLinks.length > 0) {
        for (let ml = 0; ml < mapLinks.length; ml++) {
            mapLinks[ml].addEventListener('click', function (e) {
                e.preventDefault();
                window.location = this.getAttribute('href');
            });
        }
    }
}