'use-strict';

import {Search, addHighlightedResults} from './search';

const addresses = window.addressProperties || false;

const escapeRegExp = (stringToGoIntoTheRegex) => {
    return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const searchFunc = function (context) {
    // if we dont't have the addresses, throw an error
    if (addresses === false) {
        throw new Error('Addresses not found');
    }

    const query = this.value;
    if (query.length > 1) {
        // if preview not yet visible, make it
        if (context.previewVisible === false) {
            context.resultsElement.classList.add('visible');
            context.resultsElement.setAttribute('aria-hidden', false);
            context.previewVisible = true;
        }

        // create new regex with the users input as the search term
        const searchRegex = escapeRegExp(query);
        const regex = new RegExp(searchRegex, 'gi');

        // get the addresses matching the regex
        const matchingAddresses = addresses.filter((value) => {
            return regex.test(value.text);
        });

        // reset search preview and add new matching items
        context.resultsElement.innerHTML = '';
        addHighlightedResults(context, query, matchingAddresses, ['id']);
    } else {
        context.resultsElement.innerHTML = '';
        context.resultsElement.classList.remove('visible');
        context.resultsElement.setAttribute('aria-hidden', true);
        context.previewVisible = false;
    }
};

// change location as with just a simple submit the GET parameters seem to get lost
const formSubmit = function (e) {
    e.preventDefault();
    window.location = this.action;
};

export const contactAddress = () => {
    const desktopAddressInput = document.querySelector('.desktop-contact-address');
    const mobileAddressInput = document.querySelector('.mobile-contact-address');

    if (desktopAddressInput === null) {
        console.info('No contact address form on this page');
        return;
    }

    const DesktopAddressSearch = Search(desktopAddressInput);
    const MobileAddressSearch = Search(mobileAddressInput);
    DesktopAddressSearch.init(searchFunc, DesktopAddressSearch);
    MobileAddressSearch.init(searchFunc, MobileAddressSearch);

    // add event listeners for when a search result was selected
    // to update the form action with the selected address id
    desktopAddressInput.addEventListener(DesktopAddressSearch.selectedEvent, function () {
        DesktopAddressSearch.form.action = DesktopAddressSearch.form.action + '?id=' + DesktopAddressSearch.selected.getAttribute('data-id');
        DesktopAddressSearch.form.querySelector('button').disabled = false;
    });
    mobileAddressInput.addEventListener(MobileAddressSearch.selectedEvent, function () {
        MobileAddressSearch.form.action = MobileAddressSearch.form.action + '?id=' + MobileAddressSearch.selected.getAttribute('data-id');
        MobileAddressSearch.form.querySelector('button').disabled = false;
    });

    // add event listeners for when a form was sent
    DesktopAddressSearch.form.addEventListener('submit', formSubmit);
    MobileAddressSearch.form.addEventListener('submit', formSubmit);
};