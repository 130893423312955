'use-strict';

export const querySelect = function (selector) {
    return document.querySelector(selector);
};

export const queryAll = function (selector) {
    return document.querySelectorAll(selector);
};

export const offset = (el) => {
    const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

/*
* Event delegation
*/
export const delegate = function (element, eventName, selector, fn, eventoptions) {
    if (element === null) {
        console.warn('Element not found', {el: element});
        return;
    }

    const options = eventoptions || {};
    element.addEventListener(eventName, function(event) {
        const possibleTargets = element.querySelectorAll(selector);
        const target = event.target;

        for (let i = 0, l = possibleTargets.length; i < l; i++) {
            let el = target;
            const p = possibleTargets[i];

            while(el && el !== element) {
                if (el === p) {
                    return fn.call(p, event);
                }

                el = el.parentNode;
            }
        }
    }, options);
};

export const replaceSpecialChars = (text, delimitter = '-') => {
    return text.replace(/[éè]/g, 'e').replace(/[âà]/g, 'a').replace(/[ç]/g, 'c')
        .replace(/[ö]/g, 'oe').replace(/[ä]/g, 'ae').replace(/[ü]/g, 'ue').replace(/[^\w]/g, delimitter);
};

export const updateUrl = (param, base) => {
    const url = window.location;
    const basepath = base != null ? base : window.location.pathname;
    window.history.replaceState(param, '', url.origin + basepath + param);
};

// match text case insensitive
const pregQuote = (str) => {
    return (str + '').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, '\\$1');
};

var accentMap = {
    'ä': 'a',
    'ö': 'o',
    'ü': 'u',
    'á': 'a',
    'é': 'e',
    'è': 'e',
    'í': 'i',
    'ó': 'o',
    'ú': 'u',
    'Ç': 'c'
};

const accentFold = (s) => {
    if (!s) { return ''; }
    var ret = '';
    for (var i = 0; i < s.length; i++) {
        ret += accentMap[s.charAt(i)] || s.charAt(i);
    }
    return ret;
};

// https://stackoverflow.com/a/280805
export const highlight = (data, query) => {
    const quoted = pregQuote(query);
    const exactMatch = data.replace(new RegExp(`(${quoted})`, 'gi'), '<mark>$1</mark>');

    // return if we have an exact match
    if (exactMatch.indexOf('<mark>') !== -1) {
        return exactMatch;
    }

    // accent fold query and text string
    const foldedText = accentFold(data);
    const foldedQuery = accentFold(quoted);
    // mark query in folded text
    const foldedMatch = foldedText.replace(new RegExp(`(${foldedQuery})`, 'gi'), '{$1}');
    // get position of markers
    const firstIndex = foldedMatch.indexOf('{');
    const lastIndex = foldedMatch.indexOf('}');
    // get the actual string to mark from the marker indexes
    const textToMark = data.substring(firstIndex, lastIndex - 1);
    return data.replace(new RegExp(`(${textToMark})`, 'gi'), '<mark>$1</mark>');
};

export const fixedEncodeURIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
};

export const expandCollapse = (el) => {
    el.classList.remove('collapsed');
    el.setAttribute('aria-expanded', true);
    const target = document.querySelector(el.getAttribute('href'));
    target.setAttribute('aria-expanded', true);
    target.classList.add('show');
};

// get the closest parent element for a css class
export const closestParent = (el, cssclass) => {
    let parent = el.parentElement;
    while (parent) {
        // don't go higher than the main element
        if (parent.nodeName === 'MAIN') {
            break;
        }

        // element found
        if (parent.classList.contains(cssclass) === true) {
            break;
        }

        parent = parent.parentElement;
    }

    return parent;
};

/* export const moonmarker = function (element, m) {
    var nodeFilter = {
        acceptNode: function (node) {
            if (/^[\t\n\r ]*$/.test(node.nodeValue)) {
                return NodeFilter.FILTER_SKIP;
            }
            return NodeFilter.FILTER_ACCEPT;
        }
    };

    var index = 0,
        matches = m.sort((a, b) => a[0] - b[0]).slice(),
        previousMatch = [-1, -1],
        match = matches.shift(),
        walker = document.createTreeWalker(
            element,
            NodeFilter.SHOW_TEXT,
            nodeFilter,
            false
        );

    let node;
    while (node = walker.nextNode()) {
        if (match == undefined) break;
        if (match[0] == previousMatch[0]) continue;

        var text = node.textContent,
            nodeEndIndex = index + node.length;

        if (match[0] < nodeEndIndex) {
            var range = document.createRange(),
                tag = document.createElement('mark'),
                rangeStart = match[0] - index,
                rangeEnd = rangeStart + match[1];

            tag.setAttribute('data-rangeStart', rangeStart);
            tag.setAttribute('data-rangeEnd', rangeEnd);
            tag.classList.add('marker');

            range.setStart(node, rangeStart);
            range.setEnd(node, rangeEnd);
            range.surroundContents(tag);

            index = match[0] + match[1];

            // the next node will now actually be the text we just wrapped, so
            // we need to skip it
            walker.nextNode();
            previousMatch = match;
            match = matches.shift();
        } else {
            index = nodeEndIndex;
        }
    }
};*/